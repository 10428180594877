<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${supplier.company_name}` }} - {{ $t("COMMON.PRODUCTS") }}
      </span>
      <div
        class="col-12 mt-3"
        v-if="
          $currentUserCan($permissions.PERM_CREATE_PRODUCTS) &&
          $currentUserCan($permissions.PERM_EDIT_PRODUCTS) &&
          $currentUserCan($permissions.PERM_DELETE_PRODUCTS)
        "
      >
        <base-button
          @click="$refs.supplierFileInput.click()"
          type="button"
          class="btn btn-sm btn-primary text-uppercase px-3"
        >
          {{ $t("SUPPLIERS.ADD_SUPPLIER_FILE") }}
          <input
            style="display: none"
            type="file"
            id="supplier-file-input"
            ref="supplierFileInput"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="addSupplierFile"
          />
        </base-button>
      </div>
    </div>

    <supplier-product-list
      :key="renderKey"
      v-if="supplier.id"
      :filterSupplier="supplier.id"
      :filterOrganization="supplier.organization.id"
      :supplier="supplier"
      :addLineText="$t('SUPPLIERS.ADD_PRODUCT')"
    />

    <modal
      :show.sync="showResultsModal"
      modal-classes="modal-secondary"
      size="xl"
    >
      <div>
        <h2>
          {{
            isDry ? $t("SUPPLIERS.SYNC_PREVIEW") : $t("SUPPLIERS.SYNC_RESULTS")
          }}
          <base-button
            v-if="isDry"
            @click="runImport"
            type="button"
            class="btn btn-sm btn-primary text-uppercase px-3"
          >
            {{ $t("SUPPLIERS.RUN_SYNCHRONISATION") }}
          </base-button>
        </h2>
        <supplier-view-supplier-products-sync-results
          :synchronisationResult="synchronisationResult"
        />
      </div>
    </modal>
  </div>
</template>
<script>
import swal from "sweetalert2";
import {
  Collapse,
  CollapseItem,
  Pagination,
  Table,
  TableColumn,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import SupplierProductList from "@/components/SupplierProduct/SupplierProductList.vue";
import SupplierViewSupplierProductsSyncResults from "./SupplierViewSupplierProductsSyncResults.vue";

export default {
  name: "supplier-view-supplier-products",

  components: {
    SupplierProductList,
    SupplierViewSupplierProductsSyncResults,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },

  props: ["supplier"],

  mixins: [requestErrorMixin],

  data() {
    return {
      supplierFile: null,
      showResultsModal: false,
      synchronisationResult: [],
      isDry: true,
      renderKey: 0,
    };
  },

  computed: {},

  methods: {
    async addSupplierFile(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      e.target.value = "";

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("files/upload", {
          image: file,
          ressource_name: "suppliers",
          ressource_id: this.supplier.id,
          field: "imports",
        });
        this.supplierFile = await this.$store.getters["files/url"];

        const results = await this.$store.dispatch(
          "suppliers/importProductsDryRun",
          {
            file: this.supplierFile,
            id: this.supplier.id,
          }
        );
        this.isDry = true;
        this.synchronisationResult = [results];
        this.showResultsModal = true;
      } catch (error) {
        await this.showRequestError(error);
      }
      swal.close();
    },

    async runImport() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const results = await this.$store.dispatch(
          "suppliers/importProductsRun",
          {
            file: this.supplierFile,
            id: this.supplier.id,
          }
        );
        this.synchronisationResult = [results];
        this.isDry = false;
        this.showResultsModal = true;
        document.body.classList.remove("modal-open");
        this.renderKey++;
        this.$emit("supplierUpdated");
      } catch (error) {
        await this.showRequestError(error);
      }
      swal.close();
    },
  },

  mounted() {},

  watch: {},
};
</script>
